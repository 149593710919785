<template>
    <div class="toolbar_main">
        <div class="osahan-breadcrumb">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a :href="'/tienda/'+sucursal_seleccionada"><i class="icofont icofont-ui-home"></i>Inicio</a></li>
                        <li class="breadcrumb-item"><a :href="'/catalogo/'+sucursal_seleccionada+'?pag='+pag+'&orderItem='+orderItem+'&orderType='+orderType+'&filter_cat='+producto.cat_id">{{producto.categoria}}</a></li>
                        <li class="breadcrumb-item active">{{producto.nombre_producto}}</li>
                    </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="products_page" style="padding: 15px 0px">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <div class="shop-detail-left">
                            <div class="item-img-grid">
                                <div class="favourite-icon"></div>
                                
                                <div id="sync1" class="owl-carousel">
                                    <div class="item" v-for="(val, key) in galeria" :key="key" :data-position="key">
                                        <img :src="val" class="img-responsive img-center">
                                    </div>

                                    <div v-if="galeria.length<1" class="item" data-position="0">
                                        <img :src="producto.url_imagen" class="img-responsive img-center">
                                    </div>
                                </div>
                                
                                <div id="sync2" class="owl-carousel">
                                    <div class="item" v-for="(val, key) in galeria" :key="key" :data-position="key">
                                        <img :src="val" class="img-responsive img-center">
                                    </div>

                                    <div v-if="galeria.length<1" class="item" data-position="0">
                                        <img :src="producto.url_imagen" class="img-responsive img-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-7 col-md-7">
                        <div class="shop-detail-right">
                            <div class="widget">
                                <div class="product-name">
                                    <p class="text-danger text-uppercase">Marca: {{producto.marca??'Sin marca'}}</p>
                                    <h1 class="mb-0">{{producto.nombre_producto}}</h1>
                                    <span class="d-block">
                                        <a :href="'/catalogo/'+sucursal_seleccionada+'?pag='+pag+'&orderItem='+orderItem+'&orderType='+orderType+'&filter_cat='+producto.cat_id">Categoria: <i>{{producto.categoria}}</i></a>
                                    </span>
                                    <span>SKU: <b>{{producto.cod_articulo??'Sin codigo'}}</b></span>
                                </div>

                                <div class="price-box"></div>
                                <div class="short-description">
                                    <h4 class="mb-0">Nota:</h4>
                                    <p class="mt-0 mb-0">
                                        <b>Este y más productos tambien puede encontrar visitando nuestras <a target="_blank" :href="'/sucursales'" >Sucursales</a></b>
                                    </p>
                                </div>

                                <div class="product-color-size-area row">
                                    <div class="color-area col-lg-6 col-md-6">
                                        <h4>Vendido por <b>{{empresa.razon_social}}</b></h4>
                                        <h5 class="mt-3"><a :href="'/catalogo/'+sucursal_seleccionada"><i class="fa fa-desktop"></i> Buscar más productos</a></h5>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <img class="review-block-image mt-2 " style="width: 40px;height:40px;" src="/img/entrega.png">
                                                <div class="review-block-name">Despacho a domicilio</div>
                                                <div class="review-block-name" style="margin-top: 0px;line-height: 20px;">
                                                    <i>Su entrega en buenas manos</i>
                                                </div>
                                            </div>

                                            <div class="col-sm-12">
                                                <img class="review-block-image mt-2 " style="width: 40px;height: 40px;" src="/img/mercado.png">
                                                <div class="review-block-name">Retira tu compra</div>
                                                <div class="review-block-name" style="margin-top: 0px;line-height: 20px;text-decoration: underline;">
                                                    <a href="javascript:void(0);" @click="verSucursales()" class="text-dark">Ver puntos de venta</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="size-area col-lg-6 col-md-6">
                                        <h4 style="font-size: 1.2rem; font-weight: bold;" class="pb-1">
                                            <span v-if="producto.descuento_web>0" class="product-desc-price">S/{{number_format(producto.precio_venta, 2)}}</span>
                                            <span class="ml-1 product-price">S/{{number_format(producto.precio_venta_real, 2)}}</span>
                                            <span v-if="producto.descuento_web>0" class="ml-1 product-discount bg-primary text-white">-{{producto.descuento_web}}%</span>
                                        </h4>
                                        <span class="badge badge-dark mt-2 mb-1" style="font-size: 0.8rem;font-weight: 500;padding-bottom: 5px;">Envío rapido</span>

                                        <a href="javascript:void(0);" @click="verDisponibilidadStock()" class="mt-0 mb-2 d-block">
                                            <i class="fa  fa-info-circle"></i> 
                                            <span id="txtStock"> Consultar disponibilidad</span>
                                        </a>
                                        <p v-if="hora_consulta" class="mb-1" style="font-size: 12px;">
                                            Disponible {{stock_disponible}} unidades <br><i>(Actualizado: {{hora_consulta}})</i>
                                        </p>

                                        <button v-if="modo_presentacion=='StoreOnline'" type="button" @click="agregarCarrito(producto, 1, false)" class="btn btn-theme-round btn-lg cursor-pointer">
                                            <i class="icofont icofont-shopping-cart"></i> Agregar al carrito
                                        </button>
                                    </div>
                                </div>

                                <div class="mt-5">
                                    <div class="payment-menthod" style="margin-bottom: 0px;">
                                        <img alt="" src="/img/payment_methods.png">
                                        <a v-if="empresa.telefono_movil" target="_blank" data-toggle="tooltip" data-placement="top" title="Deseo más información" data-original-title="Deseo más información" style="border-radius: 50% !important; background: #25D366; color: white;" :href="'https://api.whatsapp.com/send?phone='+empresa.telefono_movil+'&text=Deseo información del producuto '+producto.nombre_producto+', con código '+producto.cod_articulo" class="btn rounded-circle ml-3">
                                            <i class="fa fa-whatsapp my-float" style="font-size: 1.4rem;font-weight: bolder;"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="reviews-section">
            <div class="container">
                <div class="section-header"></div>
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <h5 class="heading-design-h5">Especificaciones técnicas</h5>
                        <table class="table table-condensed table-striped">
                            <tr v-for="(val, key) in esp_tecnicas" :key="key">
                                <td>{{key}}</td>
                                <td>{{val}}</td>
                            </tr>

                            <tr v-if="esp_tecnicas.length<1">
                                <td colspan="2"><center><i>No se registró las especificaciones técnicas</i></center></td>
                            </tr>
                        </table>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-6 ">
                        <h5 class="heading-design-h5">Información adicional</h5>
                        <p class="mb-0" style="text-align: justify;" v-html="producto.inf_adicional"></p>
                        <p v-if="producto.inf_adicional=='' || !producto.inf_adicional" style="text-align: justify;">
                            <center><i>No se registró la información adicional del producto</i></center> 
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section v-if="ProductosRelacionados.length>0" class="deals-of-the-day">
            <div class="container">
                <div class="section-header">
                    <h5 class="heading-design-h5">Productos relacionados a <span class="text-danger">{{producto.categoria}}</span></h5>
                </div>

                <div class="row">
                    <div v-for="(val, key) in ProductosRelacionados" :key="key" class="col-lg-3 col-md-6 col-sm-6">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item-image">
                                    <span class="like-icon"></span>
                                    <a :href="'/producto/'+sucursal_seleccionada+'/'+val.prod_id">
                                        <img class="card-img-top img-fluid" :src="val.url_imagen" :alt="val.nombre_producto">
                                    </a>
                                </div>
                                
                                <div class="product-item-body">
                                    <div class="product-item-action">
                                        <a v-if="modo_presentacion=='StoreOnline'" data-toggle="tooltip" data-placement="top" class="btn btn-theme-round btn-sm mr-3" href="javascript:void(0)" @click="agregarCarrito(val, 1, false)" title="Agregar a carrito">
                                            <i class="icofont icofont-shopping-cart"></i>
                                        </a>
                                        <a data-toggle="tooltip" data-placement="top" class="btn btn-theme-round btn-sm" :href="'/producto/'+sucursal_seleccionada+'/'+val.prod_id" title="Ver detalle">
                                            <i class="icofont icofont-eye"></i>
                                        </a>
                                    </div>
                                    <h4 class="card-title text-truncate"><a :href="'/producto/'+sucursal_seleccionada+'/'+val.prod_id">{{val.nombre_producto}}</a></h4>
                                    <h5>
                                        <span v-if="val.descuento_web>0" class="product-desc-price mr-2">S/{{number_format(val.precio_venta, 2)}}</span>
                                        <span class="product-price mr-2">S/{{number_format(val.precio_venta_real, 2)}}</span>
                                        <span v-if="val.descuento_web>0" class="product-discount">{{val.descuento_web}}% Off</span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
        <section class="top-brands" v-if="marcas_top_producto.length>0">
            <div class="container">
                <div class="section-header">
                    <h5 class="heading-design-h5">Top Marcas relacionados <span class="badge badge-primary">{{marcas_top_producto.length}} Marca</span></h5>
                </div>
                <div class="row text-center">
                    <div class="col-lg-2 col-md-2 col-sm-2" v-for="(value, key) in marcas_top_producto" :key="key">
                        <img class="img-responsive" :src="value.url_logo" :alt="value.marca">
                    </div>
                </div>
            </div>
        </section>
        <!---->

        <Modal ref="form_puntos_venta" dialog="modal-md">
            <div class="modal-header">
                <h1 class="modal-title"> <i class="fa fa-map-marker"></i> Puntos de venta </h1>
            </div>

            <div :class="['modal-body']">
                <div :class="['row']">
                    <div :class="['col-lg-12', 'col-12', 'form-group']">
                        <div v-for="(val, key) in sucursales" :key="key" class="card shadow-sm hover-shadow mt-1">
                            <div class="card-body pt-2 pb-0">
                                <h1> {{val.sucursal}} </h1>
                                <p class="p-0 mb-0"><i class="fa fa-map-marker"></i> {{val.direccion}}</p>
                                <p class="p-0 mb-0"><i class="fa fa-clock-o"></i>  {{val.horario_atencion??'Aún no definen horario de atención'}} </p>
                                <p class="p-0 mb-0"><i class="fa fa-whatsapp"></i> {{val.telefonos??'-'}} </p>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
        </Modal>
        <Login ref="form_login" :title_modal="'¡Hola!, Para comprar, ingresa a tu cuenta'"></Login>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    import Modal from '@/components/Modal.vue';
    import Login from '@/components/Login.vue';

    export default {
        name: 'producto',
        computed:{
            ...mapState(['mi_cuenta','empresa', 'sucursales', 'redes_sociales', 'sucursal_seleccionada']),
        },
        components: {
            Modal,
            Login,
		},
        created(){
            this.loadObjEmpresa();
            this.loadObjSucursalSeleccionada();
            this.loadObjPerfilUsuario();
        },
        mounted(){
            this.getDatosProducto();
            
            document.title = "Bienvenido a "+((this.empresa.abreviatura)?this.empresa.abreviatura:"-");
        },
        data(){
            return{
                pag: (this.$route.query.pag)?this.$route.query.pag:1,
                orderItem: (this.$route.query.orderItem)?this.$route.query.orderItem:"oferta",
                orderType: (this.$route.query.orderType)?this.$route.query.orderType:"desc",

                slug: (this.$route.params.slug)?this.$route.params.slug:'none',
                cat_relacionado:'',
                producto:{},
                galeria:[],
                marcas_top_producto:[],
                esp_tecnicas:[],
                info_adicional:[],
                ProductosRelacionados:[],

                stock_disponible:0,
                hora_consulta:"",
                modo_presentacion: process.env.VUE_APP_MODE,
            }
        },
        methods:{
            ...mapMutations(['setLoading', 'setFiltroCategoria']),
            ...mapActions(['loadObjPerfilUsuario', 'loadObjEmpresa', 'loadObjSucursalSeleccionada']),
            getDatosProducto(){
                this.setLoading(true);
                this.$http.get('tiendaVirtual/producto/'+this.sucursal_seleccionada+'/'+this.slug).then((res)=>{
                    if(res.data.Product.length<1){
                        window.location = "/NotFound";
                        return;
                    }
                    this.producto               = res.data.Product;
                    this.galeria                = res.data.Product.galeria;
                    this.esp_tecnicas           = res.data.Product.esp_tecnicas;
                    this.info_adicional         = res.data.Product.info_adicional;
                    this.ProductosRelacionados  = res.data.Products_rel;
                    this.marcas_top_producto    = res.data.marcas_top;

                    this.stock_disponible       = this.producto.stock;
                    setTimeout(()=>{
                        this.owlCarousel();
                        $('[data-toggle="tooltip"]').tooltip();
                    }, 200);
                }).finally(()=>{
                    setTimeout(()=>{
                        this.setLoading(false);
                    }, 1400);
                });
            },
            verSucursales(){
                this.$refs.form_puntos_venta.open();
            },
            verDisponibilidadStock(){
                this.setLoading(true);
                this.$http.get('tiendaVirtual/get_disponibilidad?sucursal='+this.sucursal_seleccionada+'&cod_producto='+this.slug).then((res)=>{
                    this.stock_disponible   = res.data.stock;
                    this.hora_consulta      = res.data.fecha_hora_consulta;
                }).finally(()=>{
                    this.setLoading(false);
                });
            },
            agregarCarrito(producto, cantidad, reemplazar_cantidad){
                
                if(!this.$store.state.mi_cuenta.cli_id){
                    this.$refs.form_login.abrir_login();
                    return;
                }
                
                if(producto.stock>=cantidad && producto.stock>0){
                    this.$store.commit('agregarCarrito',{
                        producto:producto,
                        cantidad:cantidad,
                        reemplazar_cantidad: reemplazar_cantidad,
                        sucursal: this.sucursal_seleccionada,
                        incremento: true
                    });
                }else{
                    toastr.warning('No hay stock disponible para este producto', 'Mensaje', { showDuration: 300, hideDuration: 12000, progressBar: true, closeButton:  true });
                }
            },
            owlCarousel(){
                let self = this;
                $("#sync1").owlCarousel({ 
                    singleItem: true
                    , slideSpeed: 1000
                    , pagination: false
                    , navigation: true
                    , responsiveRefreshRate: 200
                    , addClassActive:true
                });

                $("#sync2").owlCarousel({
                    items: 5,
                    navigation: false,
                    itemsDesktop: [1199, 10],
                    itemsDesktopSmall: [979, 10],
                    itemsTablet: [768, 8],
                    itemsMobile: [479, 4],
                    pagination: false,
                    responsiveRefreshRate: 100,
                    afterInit: function (el) {
                        el.find(".owl-item").eq(0).addClass("synced");
                    },
                    navigationText:["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"]
                });
                $(".owl-next").html("<i class='fa fa-chevron-right'></i>");
                $(".owl-prev").html("<i class='fa fa-chevron-left'></i>");

                $("#sync1").on("click", ".owl-next, .owl-prev", function (e) {
                    e.preventDefault();
                    self.syncPosition();
                });

                $("#sync2").on("click", ".owl-item", function (e) {
                    e.preventDefault();
                    var number = $(this).data("owlItem");
                    $("#sync1").trigger("owl.goTo", number);
                });
            },
            syncPosition(){
                var current = $("#sync1").find(".owl-item.active div").data("position");
                $("#sync2").find(".owl-item").removeClass("synced").eq(current).addClass("synced");
                if ($("#sync2").data("owlCarousel") !== undefined) {
                    this.center(current);
                }
            },
            center(number){
                var sync2visible = $("#sync2").data("owlCarousel").owl.visibleItems;
                var num = number;
                var found = false;
                for (var i in sync2visible) {
                    if (num === sync2visible[i]) {
                        var found = true;
                    }
                }
                if (found === false) {
                    if (num > sync2visible[sync2visible.length - 1]) {
                        $("#sync2").trigger("owl.goTo", num - sync2visible.length + 2);
                    } else {
                        if (num - 1 === -1) {
                            num = 0;
                        }
                        sync2.trigger("owl.goTo", num);
                    }
                } else if (num === sync2visible[sync2visible.length - 1]) {
                    $("#sync2").trigger("owl.goTo", sync2visible[1]);
                } else if (num === sync2visible[0]) {
                    $("#sync2").trigger("owl.goTo", num - 1);
                }
            },
            filtrar_productos(cat_id){
                this.setFiltroCategoria(cat_id.split(','));

                let filter_categoria    = this.$store.state.categoria_filtro;
                let filter_marca        = this.$store.state.marca_filtro;
                let filter_producto     = this.$store.state.producto_filtro;
                let filter_price        = this.$store.state.precio_filtro;
                let filter_sucursal     = this.$store.state.sucursal_seleccionada;

                let get_str = "pag="+this.pag;
                if(filter_producto)
                    get_str+="&filter_product="+filter_producto;
                if(this.orderItem)
                    get_str+= "&orderItem="+this.orderItem;
                if(this.orderType)
                    get_str+= "&orderType="+this.orderType;
                if(filter_categoria.length)
                    get_str+= "&filter_cat="+filter_categoria;
                if(filter_marca.length)
                    get_str+= "&filter_marc="+filter_marca;
                if(filter_price.length)
                    get_str+="&filter_price="+filter_price;
                //window.location.href    = '/tienda?'+get_str;
                console.log('/catalogo/'+filter_sucursal+'?'+get_str);
            },
        }
    }
</script>